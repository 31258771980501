.banner_create{
     background-color: rgba(79,67,210,1);
     border-radius: 5px;
     padding: 5px 15px 5px 10px;
     margin-right: 10px;
     cursor: pointer;
}
.banner_create>button{
     border: none;
     color: white;
     margin-left: 5px;
     background-color: rgba(79,67,210,1);
}
.banner-rediract-activity-root{
     height: 55px !important;
     margin: 0 !important;
}