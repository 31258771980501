
.__btn{
     border: 0;
     color: white;
     text-transform: capitalize;
     border-radius: 5px;
     padding: 10px 0;
     background: #5213ff;
}

.App {
     text-align: center;
   }
   
   .App-logo {
     height: 40vmin;
     pointer-events: none;
   }
   
   @media (prefers-reduced-motion: no-preference) {
     .App-logo {
       animation: App-logo-spin infinite 20s linear;
     }
   }
   
   .App-header {
     background-color: #282c34;
     min-height: 100vh;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     font-size: calc(10px + 2vmin);
     color: rgb(13, 104, 240);
   }
   
   .App-link {
     color: #61dafb;
   }
   
   .p-select{
     padding: 10px 30px !important;
   }
   
   @keyframes App-logo-spin {
     from {
       transform: rotate(0deg);
     }
     to {
       transform: rotate(360deg);
     }
   }