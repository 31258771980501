.Deshboard_Item {
     height: 140px;
     margin: 0;
     padding: 0 5px;
}

.Deshboard_Item_inner{
     height: 100%;
     width: 100%;
     box-shadow: 5px 5px 20px #e4e1e1;
     border-radius: 5px;
     padding: 25px 20px 0px 15px;
     /* background: linear-gradient(153deg, rgba(245,237,161,1) 0%, rgba(195,163,244,1) 100%); */
     /* background: linear-gradient(153deg, rgba(79,67,210,1) 0%, rgba(195,163,244,1) 100%); */
     background: linear-gradient(153deg, rgba(79,67,210,1) 0%, rgba(9,50,152,1) 100%);
}